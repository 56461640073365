/* eslint-disable no-unused-vars */
import * as S from './Register.styles'

import templateImage from '../../assets/templateImage.png'
import { useHistory } from 'react-router'
import { motion } from 'framer-motion'
import UnauthenticatedTemplate from '../../components/unauthenticatedTemplate/Unauthenticated'
import BackButton from '../../components/backButton/BackButton'
import { useContext, useState } from 'react'
import { Button, Hidden, Paragraph } from 'applaus-ui-kit'
import Logo from '../../assets/logo.svg'
import { ButtonBack } from '../../components/buttonBack/ButtonBack'
import { useWindowSize } from '../../hooks/useWindowSize'
import { AuthContext } from '../../contexts/AuthContext'

export const Register = () => {
  const history = useHistory()
  const { isMobile } = useWindowSize()
  const { setModalState } = useContext(AuthContext)

  const [buttonSignup, setButtonSignup] = useState<{
    customer: boolean
    creator: boolean
  }>({ customer: false, creator: false })
  return (
    <>
      <UnauthenticatedTemplate image={templateImage}>
        <S.Wrapper>
          <Hidden mobile>
            <S.Logo src={Logo} />
          </Hidden>
          <S.Container>
            <S.BackButtonBox>
              <ButtonBack
                title={isMobile ? '' : 'Voltar'}
                click={() => history.goBack()}
              />
            </S.BackButtonBox>
            <S.TextProfile>Perfil</S.TextProfile>
            <Hidden tablet desktop>
              <Paragraph
                type="normal"
                variant="large"
                className="mobileParagraph">
                {buttonSignup.customer
                  ? 'Compre ingressos para eventos e/ou conteúdos digitais'
                  : 'Crie eventos e conteúdos digitais'}
              </Paragraph>
            </Hidden>
            <S.SignupBox>
              <S.SelectSignupBox>
                <S.SelectSignup
                  selected={buttonSignup.customer}
                  onClick={() =>
                    setButtonSignup({ customer: true, creator: false })
                  }>
                  <S.UserIcon selected={buttonSignup.customer} />
                  <Paragraph type="bold" variant="large">
                    Cliente
                  </Paragraph>
                </S.SelectSignup>
                <p className="or">ou</p>
                <S.SelectSignup
                  selected={buttonSignup.creator}
                  onClick={() =>
                    setButtonSignup({ customer: false, creator: true })
                  }>
                  <S.CouponIcon selected={buttonSignup.creator} />
                  <Paragraph type="bold" variant="large">
                    Produtor
                  </Paragraph>
                </S.SelectSignup>
              </S.SelectSignupBox>
            </S.SignupBox>

            <Hidden mobile>
              <Paragraph
                type="normal"
                variant="large"
                className="mobileParagraph">
                {buttonSignup.customer
                  ? 'Compre ingressos para eventos e/ou conteúdos digitais'
                  : buttonSignup.creator
                  ? 'Crie eventos e conteúdos digitais'
                  : ''}
              </Paragraph>
            </Hidden>
            <S.ButtonBox>
              <div>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  fullWidth
                  disabled={!buttonSignup.customer && !buttonSignup.creator}
                  onClick={() =>
                    buttonSignup.customer
                      ? history.push('/customersignup')
                      : buttonSignup.creator
                      ? history.push('/creatorsignup')
                      : null
                  }>
                  Continuar
                </Button>
              </div>
            </S.ButtonBox>
          </S.Container>
        </S.Wrapper>
      </UnauthenticatedTemplate>
    </>
  )
}
