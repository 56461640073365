import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing: border-box;
    font-family: Poppins;
    font-size: 8px;
  }
  body, input, button {
    font-size: 8px;
  }
  button { 
    cursor: pointer;
    font-size: 8px;
  }
  body, #root {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  }

  html {
    height: -webkit-fill-available;
  }

`
