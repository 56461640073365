import {
  Button,
  Input,
  InputMask,
  RadioButton,
  Paragraph,
  Hidden
} from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { history } from '../../routes/services/history'
import UnauthenticatedTemplate from '../../components/unauthenticatedTemplate/Unauthenticated'
import * as S from './CreatorSignup.styles'
import {
  validationSchemaCompany,
  validationSchemaPersonal
} from './validationSchema'

import TemplateImage from '../../assets/templateImage.png'
import { ButtonBack } from '../../components/buttonBack/ButtonBack'
import { registerCreatorService } from '../../api/services/creator/registerCreator'
import { AuthContext } from '../../contexts/AuthContext'
import Logo from '../../assets/logo.svg'
import { useWindowSize } from '../../hooks/useWindowSize'
import jsonStates from '../../utils/estados-cidades.json'
import ReactSelect from 'react-select'
import { getCepService } from '../../api/services/getCepService'

export const CreatorSignup = () => {
  const { setLoading, setModalState } = useContext(AuthContext)
  const [isCompany, setIsCompany] = useState(false)
  const { isMobile } = useWindowSize()
  const [states] = useState<{ value: string; label: string }[]>(
    jsonStates.estados.map((state) => ({
      value: state.sigla,
      label: state.nome
    }))
  )
  const [cities, setCities] = useState<{ value: string; label: string }[]>([])

  const [selectedState, setSelectedState] = useState('')

  const [stateError, setStateError] = useState('')

  useEffect(() => {
    if (selectedState !== '') {
      setCities(
        jsonStates.estados
          .filter((state) => state.sigla === selectedState)[0]
          .cidades.map((city) => ({
            value: city,
            label: city
          }))
      )
    }
  }, [selectedState])

  const resetAlertModal = () => {
    setModalState({
      message: '',
      open: false,
      variant: 'error'
    })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      corporateName: '',
      document: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      city: '',
      state: '',
      district: '',
      zipCode: '',
      street: '',
      number: ''
    },
    validationSchema: isCompany
      ? validationSchemaCompany
      : validationSchemaPersonal,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        formik.setErrors({})
        await registerCreatorService({
          firstName: values.name,
          lastName: isCompany ? values.name : values.lastName,
          document: values.document,
          email: values.email,
          documentType: isCompany ? 'CNPJ' : 'CPF',
          password: values.password,
          phone: values.phone,
          corporateName:
            values.corporateName !== '' ? values.corporateName : undefined,
          address: {
            city: values.city,
            state: values.state,
            country: 'BR',
            district: values.district,
            number: Number(values.number),
            street: values.street,
            zipCode: values.zipCode
          }
        })
        formik.resetForm()
        setLoading(false)
        setModalState({
          message: 'Parabéns! Cadastro feito com sucesso.',
          open: true,
          variant: 'success',
          click: () => {
            setModalState({
              message: '',
              open: false,
              variant: 'error'
            })
            history.push('/')
          }
        })
      } catch (err: any) {
        setLoading(false)
        setModalState({
          open: true,
          variant: 'error',
          message: 'Ocorreu algum erro, tente novamente mais tarde.',
          buttonText: 'Fechar',
          click: () => resetAlertModal()
        })
      }
    }
  })

  const handleCep = async (e: string) => {
    if (e.replace('-', '').replace(' ', '').length > 7) {
      const response: any = await getCepService(e)
      if (response.erro === undefined) {
        setSelectedState(response.uf.toUpperCase())
        formik.setFieldValue('street', response.logradouro)
        formik.setFieldValue('state', response.uf.toUpperCase())
        formik.setFieldValue('city', response.localidade)
        formik.setFieldValue('district', response.bairro)
      }
    }
  }

  useEffect(() => {
    console.log(formik.errors.state)
    if (formik.values.state !== '') {
      setStateError('')
    } else setStateError(formik.errors.state ?? '')
  }, [formik.values.state, formik.touched.state, formik.errors.state])

  return (
    <S.Container>
      <UnauthenticatedTemplate image={TemplateImage}>
        <S.Wrapper>
          <Hidden mobile>
            <S.Logo src={Logo} />
          </Hidden>
          <S.ContentForm>
            <ButtonBack
              title={isMobile ? '' : 'Voltar'}
              click={() => history.goBack()}
            />
            <S.TextPassword>Cadastre-se</S.TextPassword>
            <S.Form onSubmit={formik.handleSubmit}>
              <S.LegalPerson>
                <RadioButton
                  name="isCompany"
                  onClick={() => {
                    setIsCompany(false)
                    formik.setFieldValue('document', '')
                    formik.setFieldValue('corporateName', '')
                    formik.setErrors({})
                  }}
                  checked={!isCompany}
                  readOnly
                />
                <Paragraph variant="large" type="bold">
                  Sou pessoa física
                </Paragraph>
              </S.LegalPerson>
              <S.LegalPerson>
                <RadioButton
                  name="isCompany"
                  onClick={() => {
                    setIsCompany(true)
                    formik.setFieldValue('document', '')
                    formik.setErrors({})
                  }}
                  checked={isCompany}
                  readOnly
                />
                <Paragraph variant="large" type="bold">
                  Sou pessoa jurídica
                </Paragraph>
              </S.LegalPerson>

              <S.TextObrigatory>*Obrigatório</S.TextObrigatory>

              <S.GroupFormDataCreator>
                <Input
                  name="name"
                  label="*Nome"
                  placeholder="Digite seu nome completo"
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  texterror={formik.errors.name}
                  onChange={formik.handleChange}
                  fullWidth
                />
                <Input
                  name="lastName"
                  label="*Sobrenome"
                  placeholder="Digite seu sobrenome"
                  value={formik.values.lastName}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  texterror={formik.errors.lastName}
                  onChange={formik.handleChange}
                  fullWidth
                />

                {isCompany ? (
                  <Input
                    name="corporateName"
                    label="*Razão Social"
                    placeholder="Digite a razão social de sua empresa"
                    value={formik.values.corporateName}
                    error={
                      formik.touched.corporateName &&
                      Boolean(formik.errors.corporateName)
                    }
                    texterror={formik.errors.corporateName}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                ) : undefined}

                <InputMask
                  name="document"
                  mask={isCompany ? '99.999.999/9999-99' : '999.999.999-99'}
                  fullWidth
                  label={isCompany ? '*CNPJ' : '*CPF'}
                  placeholder={
                    isCompany ? '00.000.000/0000-00' : '000.000.000-00'
                  }
                  onChange={formik.handleChange}
                  value={formik.values.document}
                  error={
                    formik.touched.document && Boolean(formik.errors.document)
                  }
                  texterror={formik.errors.document}
                />

                <Input
                  name="email"
                  label="*Email"
                  placeholder="Digite seu email"
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  texterror={formik.errors.email}
                  onChange={formik.handleChange}
                  fullWidth
                />

                <InputMask
                  name="phone"
                  mask="(99) 9 9999-9999"
                  label="*Telefone"
                  placeholder="(00) 9 0000-0000"
                  value={formik.values.phone}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  texterror={formik.errors.phone}
                  onChange={formik.handleChange}
                  fullWidth
                />

                <InputMask
                  mask="99999-999"
                  name="zipCode"
                  label="*CEP"
                  placeholder="Digite seu CEP"
                  value={formik.values.zipCode}
                  error={
                    formik.touched.zipCode && Boolean(formik.errors.zipCode)
                  }
                  texterror={formik.errors.zipCode}
                  onChange={(e) => {
                    formik.handleChange(e)
                    handleCep(e.target.value)
                  }}
                  fullWidth
                />

                <div style={{ marginBottom: '16px' }}>
                  <S.Label>*Estado</S.Label>
                  <ReactSelect
                    options={states}
                    onChange={(e) => {
                      setSelectedState(e!.value)
                      formik.setFieldValue('state', e!.value)

                      formik.setFieldValue('city', '')
                    }}
                    value={states.filter(
                      (obj) => formik.values.state === obj.value
                    )}
                    noOptionsMessage={() => 'Estado não encontrado'}
                    placeholder="Estado"
                    styles={S.customStyles}
                  />
                  <S.Error>
                    {formik.touched.state && stateError !== ''
                      ? stateError
                      : null}
                  </S.Error>
                </div>
                <div style={{ marginBottom: '16px' }}>
                  <S.Label>*Cidade</S.Label>
                  <ReactSelect
                    options={cities}
                    onChange={(e) => formik.setFieldValue('city', e!.value)}
                    value={cities.filter(
                      (obj) => formik.values.city === obj.value
                    )}
                    noOptionsMessage={() =>
                      selectedState
                        ? 'Cidade não encontrada'
                        : 'Selecione o estado'
                    }
                    placeholder="Cidade"
                    styles={S.customStyles}
                  />
                  <S.Error>
                    {formik.touched.city && Boolean(formik.errors.city)
                      ? formik.errors.city
                      : null}
                  </S.Error>
                </div>

                <Input
                  name="street"
                  label="*Endereço"
                  placeholder="Digite seu endereço"
                  value={formik.values.street}
                  error={formik.touched.street && Boolean(formik.errors.street)}
                  texterror={formik.errors.street}
                  onChange={formik.handleChange}
                  fullWidth
                />
                <Input
                  name="district"
                  label="*Bairro"
                  placeholder="Digite seu bairro"
                  value={formik.values.district}
                  error={
                    formik.touched.district && Boolean(formik.errors.district)
                  }
                  texterror={formik.errors.district}
                  onChange={formik.handleChange}
                  fullWidth
                />
                <Input
                  name="number"
                  label="*Número"
                  type="number"
                  placeholder="Digite seu número"
                  value={formik.values.number}
                  error={formik.touched.number && Boolean(formik.errors.number)}
                  texterror={formik.errors.number}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </S.GroupFormDataCreator>
              <Input
                label="*Senha"
                name="password"
                placeholder="Senha"
                type="password"
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                texterror={formik.errors.password}
                onChange={formik.handleChange}
                fullWidth
                value={formik.values.password}
              />
              <Input
                label="*Confirmar Senha"
                name="confirmPassword"
                placeholder="Confirmar Senha"
                type="password"
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                texterror={formik.errors.confirmPassword}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                fullWidth
              />
              <S.PasswordRules>
                <S.PasswordRule>* Pelo menos 8 caracteres</S.PasswordRule>
                <S.PasswordRule>* No mínimo 1 letra e 1 número</S.PasswordRule>
              </S.PasswordRules>
              <Button
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                type="submit">
                Cadastrar
              </Button>
            </S.Form>
          </S.ContentForm>
        </S.Wrapper>
      </UnauthenticatedTemplate>
    </S.Container>
  )
}
