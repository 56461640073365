import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media (min-width: 1170px) {
    justify-content: center;
  }
`

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 1rem 0;
    > button {
      margin-top: auto;
    }
    @media (min-width: 1170px) {
      margin: 0 auto;
      max-width: 320px;
      justify-content: center;
      > button {
        margin-top: 0;
      }
    }
  `}
`

export const ForgotPassword = styled.a`
  ${({ theme }) => css`
    color: ${theme.color.brand.primary.nth2};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    text-decoration: underline;
    cursor: pointer;
  `}
`
