import * as S from './SocialLogin.styles'

import googleIcon from '../../../../assets/icons/google.svg'
import facebookIcon from '../../../../assets/icons/facebook.svg'

export const SocialLogin = () => {
  return (
    <S.Container>
      <S.TitleDiv>
        <S.SemiDivider />
        <S.Text>ou</S.Text>
        <S.SemiDivider />
      </S.TitleDiv>
      <S.SocialLoginButton>
        <S.SocialIcon src={googleIcon} />
        <p>Continuar com o Google</p>
      </S.SocialLoginButton>
      <S.SocialLoginButton>
        <S.SocialIcon src={facebookIcon} />
        <p>Continuar com o Facebook</p>
      </S.SocialLoginButton>
    </S.Container>
  )
}
