import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { ReactComponent as UserFile } from '../../assets/icons/user.svg'
import { ReactComponent as CouponFile } from '../../assets/icons/coupon.svg'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: auto;
    height: 100%;
    width: 100%;
    padding: ${theme.spacing.stack.xxs};
    @media (min-width: 768px) {
      padding: ${theme.spacing.stack.xxl};
    }
    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.sm} ${theme.spacing.stack.xxxl};
    }
  `}
`

export const Logo = styled.img`
  ${({ theme }) => css`
    max-width: 200px;
    max-height: 75px;
    margin: 0 auto ${theme.spacing.stack.xxs};
    @media (min-width: 768px) {
      margin: 0 auto ${theme.spacing.stack.md};
    }
  `}
`

export const BackButtonBox = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.sm};
  `}
`

export const Container = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .mobileParagraph {
      margin: ${theme.spacing.stack.xxxs} 0;
      min-height: 48px;
    }
    @media (min-width: 768px) {
      min-width: 600px;
      max-width: 600px;
    }
    @media (min-width: 1171px) {
      min-width: 418px;
      max-width: 418px;
      .mobileParagraph {
        min-height: 48px;
      }
    }
  `}
`

export const TextProfile = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    line-height: ${theme.spacing.stack.sm};
    font-weight: ${theme.font.weight.semiBold};
  `}
`

export const SignupBox = styled.div`
  @media (max-width: 767px) {
    margin: 0 auto;
  }
`

export const SelectSignupBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: ${theme.spacing.stack.xxxs} 0;
    .or {
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
      font-weight: ${theme.font.weight.semiBold};
      margin: ${theme.spacing.stack.xxxs} auto;
      @media (min-width: 768px) {
        margin: auto ${theme.spacing.stack.xxxs};
      }
    }
    @media (min-width: 768px) {
      flex-direction: row;
    }
  `}
`

export const SelectSignup = styled.div<{ selected: boolean }>`
  ${({ theme, selected }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing.stack.sm} ${theme.spacing.stack.md};
    background: ${selected ? '#8601C9' : '#F4F4F4'};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: ${theme.border.radius.large};
    cursor: pointer;
    > p {
      color: ${selected ? '#ffffff' : '#808080'};
    }
  `}
`

export const UserIcon = styled(UserFile)<{ selected: boolean }>`
  ${({ theme, selected }) => css`
    fill: ${selected ? '#ffffff' : '#808080'};
    margin-bottom: ${theme.spacing.stack.small};
  `}
`

export const CouponIcon = styled(CouponFile)<{ selected: boolean }>`
  ${({ theme, selected }) =>
    css`
      fill: ${selected ? '#ffffff' : '#808080'};
      margin-bottom: ${theme.spacing.stack.small};
    `}
`

export const Paragraph = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    line-height: 3rem;
    margin-top: 2rem;
  `}
`

export const ButtonBox = styled.div`
  ${({ theme }) => css`
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${theme.spacing.stack.xxs};
    @media (min-width: 1170px) {
      margin-top: 7rem;
    }
  `}
`
