import axios, { AxiosResponse } from 'axios'

type Response = {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

const getCepService = async (param: string) => {
  const { data }: AxiosResponse<Response> = await axios.get(
    `https://viacep.com.br/ws/${param}/json/`
  )
  return data
}

export { getCepService }
