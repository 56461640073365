import * as S from './Terms.styles'

import TermsImageDesktop from '../../../assets/mocks/termsImageDesktop.png'
import TermsImageTablet from '../../../assets/mocks/termsImageTablet.png'
import { useState } from 'react'
import { TermsOfUse } from '../components/TermsOfUse/TermsOfUse'
import { CancellationPolicy } from '../components/CancellationPolicy/CancellationPolicy'
import { PrivacyPolicy } from '../components/PrivacyPolicy/PrivacyPolicy'
import { CookieDeclaration } from '../components/CookieDeclaration/CookieDeclaration'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { Heading } from '../../../components/Typograpgh/Heading'
import { Subtitle } from '../../../components/Typograpgh/Subtitle'
import { ButtonBack } from '../../../components/buttonBack/ButtonBack'
import { history } from '../../../routes/services/history'

export const TermsDesktop = () => {
  const [descriptionState, setDescriptionState] = useState<{
    terms: boolean
    privacy: boolean
    cancellation: boolean
    cookies: boolean
  }>({ terms: true, cancellation: false, cookies: false, privacy: false })
  const { isDesktop } = useWindowSize()
  return (
    <S.Container>
      <S.TitlePage>
        {isDesktop ? (
          <div>
            <S.CoverUrl src={TermsImageDesktop} />
          </div>
        ) : (
          <S.CoverUrl src={TermsImageTablet} />
        )}
        <S.GoBackButton>
          <ButtonBack
            title="Voltar"
            click={() => history.push('/')}
            colorText="white"
          />
        </S.GoBackButton>
        <S.TitlePageText>
          <Heading variant="h2">
            TERMOS E<br />
            POLÍTICAS
          </Heading>
          <Subtitle variant="regular">
            Encontre aqui tudo o que precisa saber, legalmente
            <br />
            falando, para utilização da plataforma Applaus
          </Subtitle>
        </S.TitlePageText>
      </S.TitlePage>

      <S.ContentOptions>
        <S.Options>
          <S.TermsOfUse
            active={descriptionState.terms}
            onClick={() => {
              setDescriptionState({
                terms: true,
                cancellation: false,
                cookies: false,
                privacy: false
              })
            }}>
            Termos de uso
          </S.TermsOfUse>
          <S.PrivacyPolicy
            active={descriptionState.privacy}
            onClick={() => {
              setDescriptionState({
                terms: false,
                cancellation: false,
                cookies: false,
                privacy: true
              })
            }}>
            Política de privacidade
          </S.PrivacyPolicy>
          <S.CancellationPolicy
            active={descriptionState.cancellation}
            onClick={() => {
              setDescriptionState({
                terms: false,
                cancellation: true,
                cookies: false,
                privacy: false
              })
            }}>
            Política de cancelamento
          </S.CancellationPolicy>
          <S.CookiesDeclaration
            active={descriptionState.cookies}
            onClick={() => {
              setDescriptionState({
                terms: false,
                cancellation: false,
                cookies: true,
                privacy: false
              })
            }}>
            Declaração de cookies
          </S.CookiesDeclaration>
        </S.Options>

        <S.DescriptionBox>
          {descriptionState.terms ? (
            <TermsOfUse />
          ) : descriptionState.cancellation ? (
            <CancellationPolicy />
          ) : descriptionState.privacy ? (
            <PrivacyPolicy />
          ) : (
            <CookieDeclaration />
          )}
        </S.DescriptionBox>
      </S.ContentOptions>
    </S.Container>
  )
}
