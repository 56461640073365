import moment from 'moment'

export const dateValidator = (dateParam?: string) => {
  if (dateParam && !dateParam.includes('_')) {
    const date = moment(dateParam, 'MM/YY').fromNow()
    if (date.includes('ago') || date.includes('Invalid')) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}
