import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    overflow-x: hidden;
  `}
`

export const TitlePage = styled.div`
  ${({ theme }) => css`
    position: relative;
  `}
`

export const GoBackButton = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.stack.xs};
    left: ${theme.spacing.stack.xxs};

    > p {
      color: ${theme.color.neutral.nth4};
    }
  `}
`

export const TitlePageText = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 20%;
    left: ${theme.spacing.stack.xxxl};

    @media (min-width: 1171px) {
      top: 30%;
      left: ${theme.spacing.stack.xxxl};
    }

    > h2 {
      z-index: 999;
      color: ${theme.color.neutral.nth4};
    }
    > p {
      z-index: 999;
      color: ${theme.color.neutral.nth4};
    }
  `}
`

export const CoverUrl = styled.img`
  ${({ theme }) => css`
    width: 100vw;
    max-height: 50vh;
  `}
`

export const Options = styled.div`
  ${({ theme }) => css``}
`

export const TermsOfUse = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    padding: ${theme.spacing.stack.xxxs};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    color: ${active ? '#FFFFFF' : '#39383A'};
    background: ${active ? '#8601C9' : ''};
    border-radius: ${theme.border.radius.medium};
    margin-bottom: ${theme.spacing.stack.xxxs};
    white-space: nowrap;
    cursor: pointer;
  `}
`

export const PrivacyPolicy = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    padding: ${theme.spacing.stack.xxxs};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    color: ${active ? '#FFFFFF' : '#39383A'};
    background: ${active ? '#8601C9' : ''};
    border-radius: ${theme.border.radius.medium};
    margin-bottom: ${theme.spacing.stack.xxxs};
    white-space: nowrap;
    cursor: pointer;
  `}
`

export const CancellationPolicy = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    padding: ${theme.spacing.stack.xxxs};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    color: ${active ? '#FFFFFF' : '#39383A'};
    background: ${active ? '#8601C9' : ''};
    border-radius: ${theme.border.radius.medium};
    margin-bottom: ${theme.spacing.stack.xxxs};
    white-space: nowrap;
    cursor: pointer;
  `}
`

export const CookiesDeclaration = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    padding: ${theme.spacing.stack.xxxs};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    color: ${active ? '#FFFFFF' : '#39383A'};
    background: ${active ? '#8601C9' : ''};
    border-radius: ${theme.border.radius.medium};
    white-space: nowrap;
    cursor: pointer;
  `}
`

export const DescriptionBox = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.stack.xs};

    @media (min-width: 1171px) {
      margin-left: ${theme.spacing.stack.xxxl};
    }
  `}
`

export const ContentOptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 0 ${theme.spacing.stack.xs};
    padding-top: ${theme.spacing.stack.xxs};

    @media (min-width: 1171px) {
      padding: 0 ${theme.spacing.stack.xxxl};
      padding-top: ${theme.spacing.stack.xxl};
    }
  `}
`
