import { Hidden, Paragraph } from 'applaus-ui-kit'
import { motion } from 'framer-motion'
import { ButtonBack } from '../../../../components/buttonBack/ButtonBack'
import { history } from '../../../../routes/services/history'
import * as S from './TermsOfUse.styles'

export const TermsOfUse = () => {
  return (
    <motion.div animate={{ opacity: [0, 1], transition: { duration: 0.5 } }}>
      <S.Container>
        <Hidden tablet desktop>
          <ButtonBack
            title="Termos de uso"
            click={() => history.push('/terms')}
          />
        </Hidden>

        <S.Wrapper>
          <S.Title>Termos de uso</S.Title>

          <Paragraph variant="large">
            1. Termos ao acessar o site da Applaus , concorda em cumprir estes
            termos de serviço, todas as leis e regulamentos aplicáveis e
            concorda que é responsável pelo cumprimento de todas as leis locais
            aplicáveis. Se você não concordar com algum desses termos, está
            proibido de usar ou acessar este site. Os materiais contidos neste
            site são protegidos pelas leis de direitos autorais e marcas
            comerciais aplicáveis.
          </Paragraph>

          <Paragraph variant="large">
            2. Uso de Licença É concedida permissão para baixar temporariamente
            uma cópia dos materiais (informações ou software) no site da Applaus
            , apenas para visualização transitória pessoal e não comercial. Esta
            é a concessão de uma licença, não uma transferência de título e, sob
            esta licença, você não pode: modificar ou copiar os materiais; usar
            os materiais para qualquer finalidade comercial ou para exibição
            pública (comercial ou não comercial); tentar descompilar ou fazer
            engenharia reversa de qualquer software contido no site da Applaus;
            remover quaisquer direitos autorais ou outras notações de
            propriedade dos materiais; ou transferir os materiais para outra
            pessoa ou espelhe os materiais em qualquer outro servidor. Esta
            licença será automaticamente rescindida se você violar alguma dessas
            restrições e poderá ser rescindida pela Applaus a qualquer momento.
            Ao encerrar a visualização desses materiais ou após o término desta
            licença, você deve apagar todos os materiais baixados em sua posse,
            seja em formato eletrónico ou impresso.
          </Paragraph>

          <Paragraph variant="large">
            3. Isenção de responsabilidade Os materiais no site da Applaus são
            fornecidos como estão. Applaus não oferece garantias, expressas ou
            implícitas, e, por este meio, isenta e nega todas as outras
            garantias, incluindo, sem limitação, garantias implícitas ou
            condições de comercialização, adequação a um fim específico ou não
            violação de propriedade intelectual ou outra violação de direitos.
            Além disso, a Applaus não garante ou faz qualquer representação
            relativa à precisão, aos resultados prováveis ou à confiabilidade do
            uso dos materiais em seu site ou de outra forma relacionado a esses
            materiais ou em sites vinculados a este site.
          </Paragraph>
        </S.Wrapper>
      </S.Container>
    </motion.div>
  )
}
