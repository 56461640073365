import { Hidden, Paragraph } from 'applaus-ui-kit'
import { motion } from 'framer-motion'
import { ButtonBack } from '../../../../components/buttonBack/ButtonBack'
import { history } from '../../../../routes/services/history'
import * as S from './CookieDeclaration.styles'

export const CookieDeclaration = () => {
  return (
    <motion.div animate={{ opacity: [0, 1], transition: { duration: 0.5 } }}>
      <S.Container>
        <Hidden tablet desktop>
          <ButtonBack
            title="Declaração de cookies"
            click={() => history.push('/terms')}
          />
        </Hidden>

        <S.Wrapper>
          <S.Title>Declaração de cookies</S.Title>

          <Hidden tablet desktop>
            <Paragraph variant="small">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              facilisis efficitur nulla non semper. Sed volutpat sapien ac magna
              vehicula, eu sodales tellus facilisis. In sit amet quam eu massa
              accumsan congue. Quisque convallis orci id justo vehicula feugiat.
            </Paragraph>

            <Paragraph variant="small">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              facilisis efficitur nulla non semper. Sed volutpat sapien ac magna
              vehicula, eu sodales tellus facilisis. In sit amet quam eu massa
              accumsan congue. Quisque convallis orci id justo vehicula feugiat.
            </Paragraph>
          </Hidden>

          <Hidden mobile>
            <Paragraph variant="regular">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              facilisis efficitur nulla non semper. Sed volutpat sapien ac magna
              vehicula, eu sodales tellus facilisis. In sit amet quam eu massa
              accumsan congue. Quisque convallis orci id justo vehicula feugiat.
            </Paragraph>

            <Paragraph variant="regular">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              facilisis efficitur nulla non semper. Sed volutpat sapien ac magna
              vehicula, eu sodales tellus facilisis. In sit amet quam eu massa
              accumsan congue. Quisque convallis orci id justo vehicula feugiat.
            </Paragraph>
          </Hidden>
        </S.Wrapper>
      </S.Container>
    </motion.div>
  )
}
