import { Hidden, Paragraph } from 'applaus-ui-kit'
import { motion } from 'framer-motion'
import { ButtonBack } from '../../../../components/buttonBack/ButtonBack'
import { history } from '../../../../routes/services/history'
import * as S from './PrivacyPolicy.styles'

export const PrivacyPolicy = () => {
  return (
    <motion.div animate={{ opacity: [0, 1], transition: { duration: 0.5 } }}>
      <S.Container>
        <Hidden tablet desktop>
          <ButtonBack
            title="Política de privacidade"
            click={() => history.push('/terms')}
          />
        </Hidden>

        <S.Wrapper>
          <S.Title>Política de privacidade</S.Title>

          <Paragraph variant="large">
            A sua privacidade é importante para nós. É política da Applaus
            respeitar a sua privacidade em relação a qualquer informação sua que
            possamos coletar no site da Applaus, e outros sites que possuímos e
            operamos. Solicitamos informações pessoais apenas quando realmente
            precisamos delas para lhe fornecer um serviço.
          </Paragraph>

          <Paragraph variant="large">
            Fazemo-lo por meios justos e legais, com o seu conhecimento e
            consentimento. Também informamos por que estamos coletando e como
            será usado. Apenas retemos as informações coletadas pelo tempo
            necessário para fornecer o serviço solicitado. Quando armazenamos
            dados, protegemos dentro de meios comercialmente aceitáveis para
            evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou
            modificação não autorizados.
          </Paragraph>

          <Paragraph variant="large">
            Não compartilhamos informações de identificação pessoal publicamente
            ou com terceiros, exceto quando exigido por lei. O nosso site pode
            ter links para sites externos que não são operados por nós. Esteja
            ciente de que não temos controle sobre o conteúdo e práticas desses
            sites e não podemos aceitar responsabilidade por suas respectivas
            políticas de privacidade. Você é livre para recusar a nossa
            solicitação de informações pessoais, entendendo que talvez não
            possamos fornecer alguns dos serviços desejados.
          </Paragraph>

          <Paragraph variant="large">
            O uso continuado de nosso site será considerado como aceitação de
            nossas práticas em torno de privacidade e informações pessoais. Se
            você tiver alguma dúvida sobre como lidamos com dados do usuário e
            informações pessoais, entre em contacto connosco.
          </Paragraph>
        </S.Wrapper>
      </S.Container>
    </motion.div>
  )
}
