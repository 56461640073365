import * as S from './TokenVerification.styles'
import { useHistory, useLocation } from 'react-router'
import UnauthenticatedTemplate from '../../../components/unauthenticatedTemplate/Unauthenticated'
import templateImage from '../../../assets/templateImage.png'
import { Button, Hidden } from 'applaus-ui-kit'
import { useContext, useState } from 'react'
import { validateTokenService } from '../../../api/services/user/validateToken'
import { sendTokenService } from '../../../api/services/user/sendTokenService'
import { ButtonBack } from '../../../components/buttonBack/ButtonBack'
import Logo from '../../../assets/logo.svg'
import { AuthContext } from '../../../contexts/AuthContext'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { sleep } from '../../../utils/sleep'

const TokenVerification = () => {
  const { setLoading, setModalState } = useContext(AuthContext)
  const [loadingButton, setLoadingButton] = useState(false)
  const { isMobile } = useWindowSize()
  const history = useHistory()
  const { state } = useLocation<{ values: { email: string } }>()
  const [pinCode, setPincode] = useState<string>('')
  const [resendTokenState, setResendTokenState] = useState(false)

  const resetAlertModal = () => {
    setModalState({
      buttonText: '',
      message: '',
      click: () => {},
      open: false,
      variant: 'error'
    })
  }

  if (!state?.values?.email) {
    history.push('/forgotpassword')
  }

  const handleStateInput = (e: string) => {
    if (e.replaceAll(/[A-Za-z]/g, '').length === 6) {
      setPincode(e)
    }
  }

  const submit = async () => {
    if (pinCode.length === 6) {
      try {
        setLoadingButton(true)
        const data = await validateTokenService({
          email: state.values.email,
          token: pinCode
        })
        await sleep(3000)
        setLoading(false)
        setLoadingButton(false)
        history.push({
          pathname: '/changepassword',
          state: { values: { id: data.id, token: pinCode } }
        })
      } catch (error: any) {
        await sleep(3000)

        setLoadingButton(false)
        if (error.response) {
          setModalState({
            buttonText: 'Tentar novamente',
            click: () => resetAlertModal(),
            message:
              error.response.status === 401
                ? 'Código de segurança inválido, tente novamente.'
                : 'Não foi possivel realizar a operação',
            open: true,
            variant: 'error'
          })
        }
      }
    }
  }

  const resendToken = async () => {
    try {
      setLoading(true)
      setResendTokenState(true)
      await sendTokenService({ email: state.values.email })
      await sleep(3000)
      setLoading(false)
      setModalState({
        buttonText: 'Ok',
        click: () => {
          resetAlertModal()
          setResendTokenState(false)
        },
        message: 'Código de segurança enviado com sucesso!',
        open: true,
        variant: 'success'
      })
    } catch (err: any) {
      await sleep(3000)
      setLoading(false)
      if (err.response) {
        setModalState({
          buttonText: 'Fechar',
          click: () => {
            resetAlertModal()
            setResendTokenState(false)
          },
          message:
            err.response.status === 429
              ? 'Você alcançou o limite de pedidos diários'
              : 'Ocorreu Algum erro, tente novamente mais tarde',
          open: true,
          variant: 'error'
        })
      } else {
        setModalState({
          buttonText: 'Fechar',
          click: () => {
            resetAlertModal()
            setResendTokenState(false)
          },
          message: 'Ocorreu Algum erro, tente novamente mais tarde',
          open: true,
          variant: 'error'
        })
      }
    }
  }

  return (
    <>
      <UnauthenticatedTemplate image={templateImage}>
        <S.Wrapper>
          <Hidden mobile>
            <S.Logo src={Logo} />
          </Hidden>
          <S.Container
            initial={{ opacity: 0, x: '-20%' }}
            transition={{ duration: 1 }}
            animate={{ opacity: 1, x: 0 }}>
            <ButtonBack
              title={isMobile ? '' : 'Voltar'}
              click={() => history.push('/forgotpassword')}
            />
            <S.TextPassword>Esqueceu sua senha?</S.TextPassword>
            <S.Content>
              <S.StyledParagraph className="sendMessage" variant="regular">
                Enviamos um código de segurança para seu e-mail:
              </S.StyledParagraph>
              <S.Email variant="regular" style={{ textAlign: 'center' }}>
                {state?.values?.email}
              </S.Email>
              <S.FormTitle>Digite o código abaixo:</S.FormTitle>
              <div className="codeInput">
                <S.CodeInputStyle
                  name="pincode"
                  inputMode="numeric"
                  fields={6}
                  onChange={handleStateInput}
                />
              </div>

              <S.ResendBox>
                <S.ResendToken
                  onClick={resendToken}
                  resendToken={resendTokenState}>
                  Reenviar código
                </S.ResendToken>

                <S.ButtonBox>
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    loading={loadingButton}
                    onClick={submit}>
                    Continuar
                  </Button>
                </S.ButtonBox>
              </S.ResendBox>
            </S.Content>
          </S.Container>
        </S.Wrapper>
      </UnauthenticatedTemplate>
    </>
  )
}

export default TokenVerification
