import { AxiosResponse } from 'axios'
import { api } from '../../config'

type Request = {
  email: string
  token: string
}

type Response = {
  id: string
}

export const validateTokenService = async (request: Request) => {
  const body = {
    email: request.email,
    token: request.token,
    clientId: process.env.REACT_APP_CLIENT_ID
  }
  const { data }: AxiosResponse<Response> = await api.post(
    '/api/v1/users/resetPassword/token/validate',
    body
  )
  return data
}
