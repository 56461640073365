import * as S from './ChangePassword.styles'
import UnauthenticatedTemplate from '../../../components/unauthenticatedTemplate/Unauthenticated'
import templateImage from '../../../assets/templateImage.png'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'
import { Button, Hidden, Input } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import { updatePasswordService } from '../../../api/services/user/updatepassword'
import { ButtonBack } from '../../../components/buttonBack/ButtonBack'
import Logo from '../../../assets/logo.svg'
import { useContext } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'
import { useWindowSize } from '../../../hooks/useWindowSize'

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Senha é obrigatória')
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      'Regras de senha não atendidas'
    ),
  confirmPassword: yup
    .string()
    .required('Confirmar senha é obrigatório')
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      'Regras de senha não atendidas'
    )
    .when('password', {
      is: (password: string | undefined) => !!(password && password.length > 0),
      then: yup.string().oneOf([yup.ref('password')], 'Senhas não coincidem')
    })
})

const ChangePasword = () => {
  const { setLoading, setModalState } = useContext(AuthContext)
  const { isMobile } = useWindowSize()
  const { state } = useLocation<{ values: { id: string; token: string } }>()
  const history = useHistory()

  const resetModalState = () => {
    setModalState({
      message: 'Senha alterada com sucesso!',
      open: false,
      variant: 'success',
      click: () => {},
      buttonText: '',
      description: ''
    })
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        await updatePasswordService({
          userId: state.values.id,
          token: state.values.token,
          password: values.password
        })
        setLoading(false)
        setModalState({
          message: 'Senha alterada com sucesso!',
          open: true,
          variant: 'success',
          click: () => {
            resetModalState()
            history.push('/')
          }
        })
      } catch (err) {
        setLoading(false)
      }
    }
  })

  if (!state) {
    history.push('/')
  }

  return (
    <UnauthenticatedTemplate image={templateImage}>
      <S.Wrapper>
        <Hidden mobile>
          <S.Logo src={Logo} />
        </Hidden>
        <S.Container
          initial={{ opacity: 0, x: '-20%' }}
          transition={{ duration: 1 }}
          animate={{ opacity: 1, x: 0 }}>
          <ButtonBack
            title={isMobile ? '' : 'Voltar'}
            click={() => history.goBack()}
          />

          <S.TextPassword>Crie sua nova senha</S.TextPassword>

          <S.Form onSubmit={formik.handleSubmit}>
            <Input
              label="Digite a sua nova senha"
              name="password"
              placeholder="Digite a sua nova senha"
              fullWidth
              error={formik.touched.password && Boolean(formik.errors.password)}
              texterror={formik.errors.password}
              onChange={formik.handleChange}
              value={formik.values.password}
              type="password"
            />
            <Input
              label="Confirme a nova senha"
              name="confirmPassword"
              placeholder="Confirme a nova senha"
              fullWidth
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              texterror={formik.errors.confirmPassword}
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              type="password"
            />
            <div>
              <S.PasswordRule>* Pelo menos 8 caracteres</S.PasswordRule>
              <S.PasswordRule>* No mínimo 1 letra e 1 número</S.PasswordRule>
            </div>
            <S.ButtonBox>
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit">
                Criar senha
              </Button>
            </S.ButtonBox>
          </S.Form>
        </S.Container>
      </S.Wrapper>
    </UnauthenticatedTemplate>
  )
}

export default ChangePasword
