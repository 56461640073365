import { Button, Input } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import * as yup from 'yup'
import TemplateImage from '../../assets/templateImage.png'
import * as S from './Login.styles'
import UnauthenticatedTemplate from '../../components/unauthenticatedTemplate/Unauthenticated'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router'
import { authService } from '../../api/services/user/authService'
import { useContext } from 'react'
import { decode } from 'jsonwebtoken'

import logo from '../../assets/logo.png'
import { SocialLogin } from './components/socialLogin/SocialLogin'
import { AuthContext } from '../../contexts/AuthContext'
import { sleep } from '../../utils/sleep'
import { useQuery } from '../../hooks/useQuery'

const customerUrl = process.env.REACT_APP_CUSTOMER_URL ?? ''
const creatorUrl = process.env.REACT_APP_CREATOR_URL ?? ''
const backofficeUrl = process.env.REACT_APP_BACKOFFICE_URL ?? ''
const cookieDomain = process.env.REACT_APP_DOMAIN ?? ''

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Insira um email válido')
    .required('Email é obrigatório'),
  password: yup.string().required('Senha é obrigatória')
})

export const Login = () => {
  const query = useQuery()
  const { setLoading, setModalState } = useContext(AuthContext)
  const history = useHistory()
  const setCustomerCookie = useCookies(['applaus-auth-customer'])[1]
  const setCreatorCookie = useCookies(['applaus-auth-creator'])[1]
  const setBackofficeCookie = useCookies(['applaus-auth-backoffice'])[1]

  const resetAlertModal = () => {
    setModalState({
      buttonText: '',
      message: '',
      click: () => {},
      open: false,
      variant: 'error'
    })
  }
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const data = await authService({
          email: values.email,
          password: values.password
        })
        await sleep(3000)

        const decoded = decode(data.accessToken)

        if (decoded && typeof decoded !== 'string') {
          switch (decoded.type) {
            case 'CUSTOMER':
              setCustomerCookie(
                'applaus-auth-customer',
                {
                  accessToken: data.accessToken,
                  refreshToken: data.refreshToken,
                  expiresIn: data.expiresIn
                },
                // { sameSite: false } // Developement
                { domain: cookieDomain } // Production
              )

              if (query.get('name') !== null) {
                window.location.href = query.get('name')!
              } else window.location.href = customerUrl

              break
            case 'CREATOR':
              setCreatorCookie(
                'applaus-auth-creator',
                {
                  accessToken: data.accessToken,
                  refreshToken: data.refreshToken,
                  expiresIn: data.expiresIn
                },
                // { sameSite: false } // Developement
                { domain: cookieDomain }
              )
              window.location.href = creatorUrl

              break
            case 'BACKOFFICE':
              setBackofficeCookie(
                'applaus-auth-backoffice',
                {
                  refreshToken: data.refreshToken
                },
                // {sameSite: false} Developement
                { domain: cookieDomain }
              )
              window.location.href = backofficeUrl

              break

            default:
              break
          }
        }

        setLoading(false)
      } catch (err) {
        await sleep(3000)
        setLoading(false)
        setModalState({
          buttonText: 'Tentar novamente',
          click: () => {
            resetAlertModal()
          },
          message:
            'Não foi possível realizar login, Email e/ou senha inválidos',
          open: true,
          variant: 'error'
        })
      }
    }
  })

  return (
    <UnauthenticatedTemplate image={TemplateImage}>
      <S.Container>
        <S.Form
          onSubmit={formik.handleSubmit}
          initial={{ opacity: 0, x: '-20%' }}
          transition={{ duration: 1 }}
          animate={{ opacity: 1, x: 0 }}>
          <S.Logo src={logo} />
          <Input
            name="email"
            label="Email"
            placeholder="Digite seu email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            texterror={formik.errors.email}
            onChange={formik.handleChange}
            value={formik.values.email}
            fullWidth
          />
          <Input
            name="password"
            label="Senha"
            placeholder="Digite sua senha"
            type="password"
            error={formik.touched.password && Boolean(formik.errors.password)}
            texterror={formik.errors.password}
            onChange={formik.handleChange}
            value={formik.values.password}
            fullWidth
          />
          <Button
            size="small"
            color="primary"
            variant="contained"
            type="submit"
            fullWidth>
            Login
          </Button>
          <SocialLogin />
          <S.Wrapper>
            <S.Link onClick={() => history.push('/forgotpassword')}>
              Esqueci minha senha
            </S.Link>
            <S.SignupText>
              Não tem conta ainda?
              <S.Link onClick={() => history.push('/register')}>
                {'  '}Inscreva-se
              </S.Link>
            </S.SignupText>
          </S.Wrapper>
        </S.Form>
      </S.Container>
    </UnauthenticatedTemplate>
  )
}
