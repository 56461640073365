import { Button, Input, InputMask } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import * as yup from 'yup'
import TemplateImage from '../../assets/templateImage.png'
import * as S from './Checkout.styles'
import UnauthenticatedTemplate from '../../components/unauthenticatedTemplate/Unauthenticated'
import { Heading } from '../../components/Typograpgh/Heading'
import { Subtitle } from '../../components/Typograpgh/Subtitle'
import { history } from '../../routes/services/history'
import BackButton from '../../components/backButton/BackButton'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { useState } from 'react'
import { dateValidator } from '../../utils/dateValidator'

const validationSchema = yup.object({
  cvc: yup.string().required('CVV é obrigatório'),
  name: yup.string().required('Nome é obrigatório'),
  number: yup.string().required('Nome é obrigatório'),
  expiry: yup
    .string()
    .test('validateImage', 'Data de válidade inválida', (value) =>
      dateValidator(value)
    )
    .required('Validade é obrigatório')
})

type CreditCardFocus = 'name' | 'number' | 'expiry' | 'cvc'

const Checkout = () => {
  const [focus, setFocus] = useState<CreditCardFocus>('name')

  const handleFocus = (inputName: CreditCardFocus) => {
    setFocus(inputName)
  }

  const formik = useFormik({
    initialValues: {
      cvc: '',
      expiry: '',
      name: '',
      number: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
    }
  })

  return (
    <UnauthenticatedTemplate image={TemplateImage}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '3rem'
        }}>
        <div style={{ width: '100%' }}>
          <BackButton title="Voltar" onClick={() => history.goBack()} />
        </div>
        <S.Container>
          <S.Form onSubmit={formik.handleSubmit}>
            <Heading variant="h1">Bem-vindo!</Heading>
            <Subtitle variant="regular">Checkout</Subtitle>
            <Cards
              cvc={formik.values.cvc}
              expiry={formik.values.expiry}
              name={formik.values.name}
              number={formik.values.number}
              focused={focus}
            />
            <Input
              label="Nome"
              name="name"
              placeholder="Nome igual no cartão"
              error={formik.touched.name && Boolean(formik.errors.name)}
              texterror={formik.errors.name}
              onChange={formik.handleChange}
              value={formik.values.name}
              fullWidth
              onFocus={() => handleFocus('name')}
            />
            <InputMask
              label="Número do cartão"
              name="number"
              mask="9999 9999 9999 9999"
              placeholder="Nome igual no cartão"
              error={formik.touched.number && Boolean(formik.errors.number)}
              texterror={formik.errors.number}
              onChange={formik.handleChange}
              value={formik.values.number}
              fullWidth
              onFocus={() => handleFocus('number')}
            />
            <InputMask
              label="Data de validade"
              name="expiry"
              mask="99/99"
              placeholder="Nome igual no cartão"
              error={formik.touched.expiry && Boolean(formik.errors.expiry)}
              texterror={formik.errors.expiry}
              onChange={formik.handleChange}
              value={formik.values.expiry}
              fullWidth
              onFocus={() => handleFocus('expiry')}
            />
            <InputMask
              label="CVV"
              name="cvc"
              mask="999"
              placeholder="Nome igual no cartão"
              error={formik.touched.cvc && Boolean(formik.errors.cvc)}
              texterror={formik.errors.cvc}
              onChange={formik.handleChange}
              value={formik.values.cvc}
              fullWidth
              onFocus={() => handleFocus('cvc')}
            />
            <Button
              size="small"
              color="primary"
              type="submit"
              variant="contained"
              fullWidth>
              Cadastrar
            </Button>
          </S.Form>
        </S.Container>
      </div>
    </UnauthenticatedTemplate>
  )
}
export { Checkout }
