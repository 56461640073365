import { api } from '../../config'

type Request = {
  userId: string
  token: string
  password: string
}

export const updatePasswordService = async (request: Request) => {
  const body = {
    password: request.password,
    token: request.token,
    clientId: process.env.REACT_APP_CLIENT_ID
  }
  await api.put(`/api/v1/users/${request.userId}/resetPassword`, body)
}
