import { useEffect, useState } from 'react'

interface Size {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

export function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    isDesktop: false,
    isMobile: false,
    isTablet: false
  })
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setWindowSize({
          isTablet: false,
          isDesktop: false,
          isMobile: true
        })
      } else if (window.innerWidth >= 768 && window.innerWidth < 1170) {
        setWindowSize({
          isTablet: true,
          isDesktop: false,
          isMobile: false
        })
      } else {
        setWindowSize({
          isTablet: false,
          isDesktop: true,
          isMobile: false
        })
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
