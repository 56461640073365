import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  width: 100%;
`

export const SemiDivider = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid #cfcfcf;
    width: 50%;
  `}
`

export const Text = styled.p`
  ${() => css`
    color: #cfcfcf;
    font-size: 2rem;
    font-weight: 600;
    margin: 0 2rem;
  `}
`

export const SocialIcon = styled.img``

export const SocialLoginButton = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 10px 16px;
    background: #f4f4f4;
    border-radius: ${theme.border.radius.large};
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin: 1rem 0;
    gap: 1rem;
    > p {
      font-size: 16px;
    }
  `}
`
