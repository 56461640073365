import * as yup from 'yup'
import cpfValidator from '../../../utils/cpfValidator'
import { phoneValidate } from '../../../utils/phoneValidate'
import { messageHelper } from '../../../utils/messageHelper'

export const customerSchema = yup.object({
  email: yup
    .string()
    .email('Insira um email válido')
    .required('Email é obrigatório'),
  name: yup
    .string()
    .required('Nome é obrigatório')
    .test(
      'name min size validation',
      messageHelper.yup.creator.error.shortName,
      (value) => {
        return !(value && value!.trim().length < 3)
      }
    ),
  lastName: yup
    .string()
    .required('Sobrenome é obrigatório')
    .test(
      'name min size validation',
      messageHelper.yup.creator.error.shortLastName,
      (value) => {
        return !(value && value!.trim().length < 3)
      }
    ),
  city: yup.string().required('Cidade é obrigatório'),
  state: yup.string().required('Estado é obrigatório'),
  cpf: yup
    .string()
    .required(messageHelper.yup.creator.error.documentIsMandatory)
    .test('document validation', 'CPF é inválido.', (value) => {
      if (value) {
        return cpfValidator(value)
      } else return false
    }),
  phone: yup
    .string()
    .required('Telefone é obrigatório.')
    .test('phone validation', 'Telefone inválido.', (value) => {
      if (value) {
        return phoneValidate(value)
      } else return false
    }),
  password: yup.string().required('Senha é obrigatória'),
  confirmPassword: yup
    .string()
    .required(messageHelper.yup.creator.error.confirmPasswordText)
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      messageHelper.yup.creator.error.rulePassowrdIncorrectText
    )
    .when('password', {
      is: (password: string | undefined) => !!(password && password.length > 0),
      then: yup
        .string()
        .oneOf(
          [yup.ref('password')],
          messageHelper.yup.creator.error.ruleMatchPasswordIncorrectText
        )
    })
})
