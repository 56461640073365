import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { theme } from 'applaus-ui-kit'

type SubtitleProps = {
  variant: 'large' | 'regular' | 'small'
}

type Theme = typeof theme

const SubtitleModifiers = {
  large: (theme: Theme) => css`
    ${media.lessThan('medium')`
        font-size: ${theme.font.size.sm};
        font-weight: ${theme.font.weight.bold};
    `}
    ${media.between('medium', 'large')`
        font-size:${theme.font.size.sm};
        font-weight: ${theme.font.weight.bold};
    `}
    ${media.greaterThan('medium')`
        font-size:${theme.font.size.md};
        font-weight: ${theme.font.weight.bold};
    `}
  `,
  regular: (theme: Theme) => css`
    ${media.lessThan('medium')`
        font-size: ${theme.font.size.xs};
        font-weight: ${theme.font.weight.bold};
    `}
    ${media.between('medium', 'large')`
        font-size:${theme.font.size.xs};
        font-weight: ${theme.font.weight.bold};
    `}
        ${media.greaterThan('medium')`
        font-size:${theme.font.size.sm};
        font-weight: ${theme.font.weight.bold};
    `}
  `,
  small: (theme: Theme) => css`
    ${media.lessThan('medium')`
        font-size: ${theme.font.size.xxs};
        font-weight: ${theme.font.weight.bold};
    `}
    ${media.between('medium', 'large')`
        font-size:${theme.font.size.xxs};
        font-weight: ${theme.font.weight.bold};
    `}
    ${media.greaterThan('medium')`
        font-size:${theme.font.size.xs};
        font-weight: ${theme.font.weight.bold};
    `}
  `
}

export const Subtitle = styled('p')<SubtitleProps>`
  ${({ theme, variant }) => css`
    ${SubtitleModifiers[variant](theme)}
    color: ${theme.color.base.nth1};
  `}
`
