import * as Yup from 'yup'
import cpfValidator from '../../utils/cpfValidator'
import { messageHelper } from '../../utils/messageHelper'
import { phoneValidate } from '../../utils/phoneValidate'
import { cnpjValidate } from '../../utils/cnpjValidate'

export const validationSchemaPersonal = Yup.object({
  name: Yup.string()
    .required(messageHelper.yup.creator.error.nameIsMandatory)
    .test(
      'name min size validation',
      messageHelper.yup.creator.error.shortName,
      (value) => {
        return !(value && value!.trim().length < 3)
      }
    ),
  lastName: Yup.string()
    .required(messageHelper.yup.creator.error.lastNameIsMandatory)
    .test(
      'lastname min size validation',
      messageHelper.yup.creator.error.shortLastName,
      (value) => {
        return !(value && value!.trim().length < 3)
      }
    ),
  document: Yup.string()
    .required(messageHelper.yup.creator.error.documentIsMandatory)
    .test(
      'document validation',
      messageHelper.yup.creator.error.documentIsInvalid,
      (value) => {
        if (value) {
          return cpfValidator(value)
        } else return false
      }
    ),
  city: Yup.string().required('Cidade é obrigatório'),
  state: Yup.string().required('Estado é obrigatório'),
  street: Yup.string().required('Endereço é obrigatório'),
  number: Yup.string()
    .required('Número é obrigatório')
    .test('numberVerify', 'Número não pode ser 0', (values) => {
      if (values && values === '0') {
        return false
      } else return true
    }),
  zipCode: Yup.string()
    .required('CEP é obrigatório')
    .test('zipCodeVerify', 'CEP inválido.', (values) => {
      if (values && values.replace(/[^0-9]/g, '').trim().length === 8) {
        return true
      } else return false
    }),
  district: Yup.string().required('Bairro é obrigatório'),
  email: Yup.string()
    .email(messageHelper.yup.creator.error.emailIsInvalid)
    .required(messageHelper.yup.creator.error.emailIsMandatory),
  phone: Yup.string()
    .required(messageHelper.yup.creator.error.phoneIsMandatory)
    .test(
      'phone validation',
      messageHelper.yup.creator.error.phoneIsInvalid,
      (value) => {
        if (value) {
          return phoneValidate(value)
        } else return false
      }
    ),
  password: Yup.string().required('Senha é obrigatória'),
  confirmPassword: Yup.string()
    .required(messageHelper.yup.creator.error.confirmPasswordText)
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      messageHelper.yup.creator.error.rulePassowrdIncorrectText
    )
    .when('password', {
      is: (password: string | undefined) => !!(password && password.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        messageHelper.yup.creator.error.ruleMatchPasswordIncorrectText
      )
    })
})

export const validationSchemaCompany = Yup.object({
  name: Yup.string().required(messageHelper.yup.creator.error.nameIsMandatory),
  lastName: Yup.string().required(
    messageHelper.yup.creator.error.lastNameIsMandatory
  ),
  corporateName: Yup.string().required(
    messageHelper.yup.creator.error.corporateNameIsMandatory
  ),
  document: Yup.string()
    .required(messageHelper.yup.creator.error.cnpjIsMandatory)
    .test(
      'document validation',
      messageHelper.yup.creator.error.cnpjIsInvalid,
      (value) => {
        if (value) {
          return cnpjValidate(value)
        } else return false
      }
    ),
  city: Yup.string().required('Cidade é obrigatório'),
  state: Yup.string().required('Estado é obrigatório'),
  street: Yup.string().required('Endereço é obrigatório'),
  number: Yup.string()
    .required('Número é obrigatório')
    .test('numberVerify', 'Número não pode ser 0', (values) => {
      if (values && values === '0') {
        return false
      } else return true
    }),
  zipCode: Yup.string()
    .required('CEP é obrigatório')
    .test('zipCodeVerify', 'CEP inválido.', (values) => {
      if (values && values.replace(/[^0-9]/g, '').trim().length === 8) {
        return true
      } else return false
    }),
  district: Yup.string().required('Bairro é obrigatório'),
  email: Yup.string()
    .email(messageHelper.yup.creator.error.emailIsInvalid)
    .required(messageHelper.yup.creator.error.emailIsMandatory),
  phone: Yup.string()
    .required(messageHelper.yup.creator.error.phoneIsMandatory)
    .test(
      'phone validation',
      messageHelper.yup.creator.error.phoneIsInvalid,
      (value) => {
        if (value) {
          return phoneValidate(value)
        } else return false
      }
    ),
  password: Yup.string().required('Senha é obrigatória'),
  confirmPassword: Yup.string()
    .required(messageHelper.yup.creator.error.confirmPasswordText)
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      messageHelper.yup.creator.error.rulePassowrdIncorrectText
    )
    .when('password', {
      is: (password: string | undefined) => !!(password && password.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        messageHelper.yup.creator.error.ruleMatchPasswordIncorrectText
      )
    })
})
