import axios from 'axios'

const url = process.env.REACT_APP_AUTH_URL

const backendUrl = process.env.REACT_APP_BACKEND_URL

const api = axios.create({
  baseURL: url
})

const backendApi = axios.create({
  baseURL: backendUrl
})

export { api, backendApi }
