import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { theme } from 'applaus-ui-kit'

type HeadingProps = {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  size?: keyof typeof theme.font.size
}

type Theme = typeof theme

const HeadingModifiers = {
  h1: (theme: Theme) => css`
    ${media.lessThan('medium')`
        font-size: ${theme.font.size.lg};
        font-weight: bold;
    `}
    ${media.between('medium', 'large')`
    font-size:${theme.font.size.xxl};
        font-weight: bold;
    `}
    ${media.greaterThan('large')`
    font-size:${theme.font.size.xxl};
    line-height: 72px;
    `}
  `,
  h2: (theme: Theme) => css`
    ${media.lessThan('medium')`
    font-size: ${theme.font.size.xl};
        font-weight: bold;
    `}
    ${media.between('medium', 'large')`
    font-size:${theme.font.size.xl};
        font-weight: bold;
    `}
    ${media.greaterThan('medium')`
    font-size:${theme.font.size.xxl};
    `}
  `,
  h3: (theme: Theme) => css`
    ${media.lessThan('medium')`
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.bold};
    line-height: ${theme.spacing.stack.xxs};  
    `}
    ${media.between('medium', 'large')`
    font-size:${theme.font.size.lg};
    font-weight: ${theme.font.weight.bold};
    line-height: ${theme.spacing.stack.md};
    `}
    ${media.greaterThan('medium')`
    font-size:${theme.font.size.lg};
    font-weight: ${theme.font.weight.bold};
    line-height: ${theme.spacing.stack.md};
    `}
  `,
  h4: (theme: Theme) => css`
    ${media.lessThan('medium')`
        font-size:${theme.font.size.md};
        font-weight: bold;
    `}
    ${media.between('medium', 'large')`
    font-size:${theme.font.size.md};
        font-weight: bold;
    `} 
    ${media.greaterThan('medium')`
        font-size:${theme.font.size.xxl};
        line-height: 72px;
    `}
  `,
  h5: (theme: Theme) => css`
    ${media.lessThan('medium')`
    font-size:${theme.font.size.sm};
        font-weight: bold;
    `}
    ${media.between('medium', 'large')`
    font-size:${theme.font.size.md};
        font-weight: bold;
    `}
    ${media.greaterThan('medium')`
    font-size:${theme.font.size.md};
    `}
  `
}

export const Heading = styled('h1').attrs<HeadingProps>(({ variant }) => ({
  as: `${variant}`
}))<HeadingProps>`
  ${({ theme, variant }) => css`
    ${HeadingModifiers[variant](theme)}
    color: ${theme.color.base.nth1};
  `}
`
