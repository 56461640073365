import { Button, Hidden, Input, InputMask } from 'applaus-ui-kit'
import { useFormik } from 'formik'
import TemplateImage from '../../assets/templateImage.png'
import * as S from './CustomerSignup.styles'
import UnauthenticatedTemplate from '../../components/unauthenticatedTemplate/Unauthenticated'
import { history } from '../../routes/services/history'
import { createCustomerService } from '../../api/services/customer/createCustomerService'
import { customerSchema } from './validation/customerSchema'
import { AxiosError } from 'axios'
import { ButtonBack } from '../../components/buttonBack/ButtonBack'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import Logo from '../../assets/logo.svg'
import ReactSelect from 'react-select'
import jsonStates from '../../utils/estados-cidades.json'
import { useWindowSize } from '../../hooks/useWindowSize'

const CustomerSignup = () => {
  const { setLoading, setModalState } = useContext(AuthContext)
  const { isMobile } = useWindowSize()
  const [states] = useState<{ value: string; label: string }[]>(
    jsonStates.estados.map((state) => ({
      value: state.sigla,
      label: state.nome
    }))
  )
  const [cities, setCities] = useState<{ value: string; label: string }[]>([])

  const [selectedState, setSelectedState] = useState('')

  useEffect(() => {
    if (selectedState !== '') {
      setCities(
        jsonStates.estados
          .filter((state) => state.sigla === selectedState)[0]
          .cidades.map((city) => ({
            value: city,
            label: city
          }))
      )
    }
  }, [selectedState])

  const resetAlertModal = () => {
    setModalState({
      message: '',
      open: false,
      variant: 'error'
    })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      cpf: '',
      email: '',
      confirmPassword: '',
      password: '',
      phone: '',
      city: '',
      state: ''
    },
    validationSchema: customerSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        await createCustomerService({
          firstName: values.name,
          lastName: values.lastName,
          document: values.cpf,
          email: values.email,
          password: values.password,
          phone: values.phone,
          address: {
            country: 'Brasil',
            city: values.city,
            state: values.state
          }
        })
        formik.resetForm()
        setLoading(false)
        setModalState({
          message: 'Parabéns! Cadastro feito com sucesso.',
          open: true,
          variant: 'success',
          click: () => {
            setModalState({
              message: '',
              open: false,
              variant: 'error'
            })
            history.push('/')
          }
        })
      } catch (err: any | AxiosError) {
        setLoading(false)
        if (err.response) {
          if (err.response.status === 409) {
            if (err.response.data.message === 'email already exists') {
              setModalState({
                buttonText: 'Fechar',
                click: () => resetAlertModal(),
                message: 'E-mail inserido já cadastrado',
                open: true,
                variant: 'error'
              })
            }

            if (err.response.data.message === 'document already exists') {
              setModalState({
                buttonText: 'Fechar',
                click: () => resetAlertModal(),
                message: 'CPF inserido já cadastrado',
                open: true,
                variant: 'error'
              })
            }
          } else {
            setModalState({
              buttonText: 'Fechar',
              click: () => resetAlertModal(),
              message: 'Ocorreu Algum erro, tente novamente mais tarde',
              open: true,
              variant: 'error'
            })
          }
        } else {
          setModalState({
            buttonText: 'Fechar',
            click: () => resetAlertModal(),
            message: 'Ocorreu Algum erro, tente novamente mais tarde',
            open: true,
            variant: 'error'
          })
        }
      }
    }
  })

  return (
    <S.Container>
      <UnauthenticatedTemplate image={TemplateImage}>
        <S.Wrapper>
          <Hidden mobile>
            <S.Logo src={Logo} />
          </Hidden>
          <S.ContentForm>
            <ButtonBack
              title={isMobile ? '' : 'Voltar'}
              click={() => history.goBack()}
            />
            <S.TextRegister>Cadastre-se</S.TextRegister>
            <S.TextObrigatory>*Obrigatório</S.TextObrigatory>
            <S.Form onSubmit={formik.handleSubmit}>
              <Input
                label="*Nome"
                name="name"
                placeholder="Nome"
                error={formik.touched.name && Boolean(formik.errors.name)}
                texterror={formik.errors.name}
                onChange={formik.handleChange}
                value={formik.values.name}
                fullWidth
              />
              <Input
                label="*Sobrenome"
                name="lastName"
                placeholder="Sobrenome"
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                texterror={formik.errors.lastName}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                fullWidth
              />
              <Input
                label="*Email"
                name="email"
                placeholder="Email"
                error={formik.touched.email && Boolean(formik.errors.email)}
                texterror={formik.errors.email}
                onChange={formik.handleChange}
                value={formik.values.email}
                fullWidth
              />

              <InputMask
                label="*Telefone"
                mask="(99) 99999-9999"
                name="phone"
                placeholder="Digite seu telefone"
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                texterror={formik.errors.phone}
                onChange={formik.handleChange}
                value={formik.values.phone}
                fullWidth
              />
              <InputMask
                mask="999.999.999-99"
                label="*CPF"
                name="cpf"
                placeholder="CPF"
                error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                texterror={formik.errors.cpf}
                onChange={formik.handleChange}
                value={formik.values.cpf}
                fullWidth
              />
              <div style={{ marginBottom: '16px' }}>
                <S.Label>*Estado</S.Label>
                <ReactSelect
                  options={states}
                  onChange={(e) => {
                    setSelectedState(e!.value)
                    formik.setFieldValue('state', e!.value)

                    formik.setFieldValue('city', '')
                  }}
                  noOptionsMessage={() => 'Estado não encontrado'}
                  placeholder="Estado"
                  styles={S.customStyles}
                />
                <S.Error>
                  {formik.touched.state && Boolean(formik.errors.state)
                    ? formik.errors.state
                    : null}
                </S.Error>
              </div>
              <div style={{ marginBottom: '16px' }}>
                <S.Label>*Cidade</S.Label>
                <ReactSelect
                  options={cities}
                  onChange={(e) => formik.setFieldValue('city', e!.value)}
                  value={cities.filter(
                    (obj) => formik.values.city === obj.value
                  )}
                  noOptionsMessage={() =>
                    selectedState
                      ? 'Cidade não encontrada'
                      : 'Selecione o estado'
                  }
                  placeholder="Cidade"
                  styles={S.customStyles}
                />
                <S.Error>
                  {formik.touched.city && Boolean(formik.errors.city)
                    ? formik.errors.city
                    : null}
                </S.Error>
              </div>

              <Input
                label="*Senha"
                name="password"
                placeholder="Senha"
                type="password"
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                texterror={formik.errors.password}
                onChange={formik.handleChange}
                value={formik.values.password}
                fullWidth
              />
              <Input
                label="*Confirmar Senha"
                name="confirmPassword"
                placeholder="Confirmar Senha"
                type="password"
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                texterror={formik.errors.confirmPassword}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                fullWidth
              />
              <S.RulesPassword>
                <S.RulePassword>* Pelo menos 8 caracteres</S.RulePassword>
                <S.RulePassword>* No mínimo 1 letra e 1 número</S.RulePassword>
              </S.RulesPassword>
              <Button
                size="large"
                color="primary"
                variant="contained"
                type="submit"
                fullWidth>
                Cadastrar
              </Button>
            </S.Form>
          </S.ContentForm>
        </S.Wrapper>
      </UnauthenticatedTemplate>
    </S.Container>
  )
}
export { CustomerSignup }
