import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { AuthProvider } from './contexts/AuthContext'
import * as serviceWorker from './serviceWorkerRegistration'

ReactDOM.render(
  <StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </StrictMode>,
  document.getElementById('root')
)

serviceWorker.register()
