import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100vh;
    overflow-y: hidden;
    min-width: 320px;
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    overflow: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing.stack.xxs};
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.sm} ${theme.spacing.stack.xxl};
    }
    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.sm} ${theme.spacing.stack.xxxl};
    }
  `}
`

export const TextRegister = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    font-weight: ${theme.font.weight.semiBold};
    color: #39383a;
    margin: ${theme.spacing.stack.xxs} 0 ${theme.spacing.stack.xxxs} 0;
    max-width: 212px;
    line-height: ${theme.spacing.stack.sm};
    @media (min-width: 768px) and (max-width: 1170px) {
      max-width: 100%;
      margin: ${theme.spacing.stack.xl} 0 ${theme.spacing.stack.xs} 0;
    }
    @media (min-width: 1171px) {
      display: none;
    }
  `}
`

export const TextObrigatory = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xxs};
    color: ${theme.color.brand.primary.nth2};
    font-weight: ${theme.font.weight.semiBold};

    @media (min-width: 768px) {
      margin-top: ${theme.spacing.stack.xs};
      margin-bottom: ${theme.spacing.stack.xs};
      font-size: ${theme.font.size.sm};
      font-weight: ${theme.font.weight.bold};
    }

    @media (min-width: 1171px) {
      margin-top: ${theme.spacing.stack.sm};
      margin-bottom: ${theme.spacing.stack.xxs};
    }
  `}
`

export const Logo = styled.img`
  ${({ theme }) => css`
    max-width: 200px;
    max-height: 75px;
    margin: 0 auto ${theme.spacing.stack.xxs};
    @media (min-width: 768px) {
      margin: 0 auto ${theme.spacing.stack.md};
    }
  `}
`

export const ContentForm = styled.div`
  ${({ theme }) => css`
    min-width: 272px;
    width: 100%;
    @media (min-width: 768px) {
      min-width: 522px;
      max-width: 522px;
    }
    @media (min-width: 1171px) {
      min-width: 418px;
      max-width: 418px;
    }
  `}
`

export const Form = styled.form`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    margin-top: ${theme.spacing.stack.xxs};
    > button {
      margin-top: ${theme.spacing.stack.xxs};
    }
    @media (min-width: 1170px) {
      margin: ${theme.spacing.stack.xs} auto 0 auto;
      max-width: 420px;
      justify-content: center;
    }
  `}
`

export const RulesPassword = styled.div`
  ${({ theme }) => css``}
`

export const RulePassword = styled.p`
  ${({ theme }) => css`
    color: #6c01a3;
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    font-weight: ${theme.font.weight.semiBold};
  `}
`

export const customStyles = {
  clearIndicator: () => ({
    border: '1px solid blue'
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    padding: '2px 16px',
    background: '#F4F4F4',
    outline: 'none',
    border: '1px solid transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderRadius: '8px',
    '&:focus-within': {
      borderColor: '#8601C9'
    }
  }),
  valueContainer: (provided: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }),
  indicatorSeparator: (provided: any) => ({
    display: 'none'
  }),
  singleValue: (provided: any) => ({
    color: '#39383A',
    fontSize: '16px',
    lineHeight: '20px'
  }),
  placeholder: (provided: any) => ({
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    borderRadius: '8px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A',
    backgroundColor: state.isSelected ? '#F4F4F4' : 'white'
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#39383A'
  })
}

export const Label = styled.p`
  ${({ theme }) => css`
    color: #39383a;
    font-size: ${theme.font.size.sm};
    font-weight: ${theme.font.weight.regular};
    line-height: 24px;
    margin-bottom: 0.5rem;
  `}
`

export const Error = styled.p`
  ${({ theme }) => css`
    color: #fc3e3e;
    font-size: ${theme.font.size.xxs};
    min-height: ${theme.spacing.stack.xxs};
  `}
`
