import { motion } from 'framer-motion'
import ReactCodeInput from 'react-code-input'
import styled, { css } from 'styled-components'

import { Paragraph } from '../../../components/Typograpgh/Paragraph'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: ${theme.spacing.stack.xxs};
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.xl} ${theme.spacing.stack.xxxl};
      align-items: center;
    }
    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.sm} ${theme.spacing.stack.xxxl};
      align-items: center;
    }
  `}
`

export const Logo = styled.img`
  ${({ theme }) => css`
    max-width: 200px;
    max-height: 75px;
    margin: 0 auto ${theme.spacing.stack.xxs};
    @media (min-width: 768px) {
      margin: 0 auto ${theme.spacing.stack.md};
    }
  `}
`

export const Container = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (min-width: 768px) and (max-width: 1170px) {
      min-width: 522px;
      max-width: 522px;
    }
    @media (min-width: 1171px) {
      min-width: 418px;
      max-width: 418px;
    }
  `}
`

export const TextPassword = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    font-weight: ${theme.font.weight.semiBold};
    color: #39383a;
    margin: ${theme.spacing.stack.xxs} 0 ${theme.spacing.stack.xxs} 0;
    max-width: 212px;
    line-height: ${theme.spacing.stack.sm};
    @media (min-width: 768px) and (max-width: 1170px) {
      max-width: 100%;
      margin: ${theme.spacing.stack.xl} 0 ${theme.spacing.stack.xs} 0;
    }
    @media (min-width: 1171px) {
      max-width: 100%;
      margin: ${theme.spacing.stack.xl} 0 ${theme.spacing.stack.small} 0;
    }
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .codeInput {
    text-align: center;
  }
  @media (min-width: 768px) {
    .codeInput {
      input {
        width: 63px;
        height: 63px;
        margin-right: 20px;
        :nth-child(6) {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: 1171px) {
    .codeInput {
      input {
        width: 58px;
        height: 58px;
        margin: 0 14px 0 0;
        :nth-child(6) {
          margin-right: 0;
        }
      }
    }
  }
`

export const StyledParagraph = styled(Paragraph)`
  ${({ theme }) => css`
    text-align: center;
    @media (min-width: 768px) and (max-width: 1170px) {
      text-align: start;
      font-size: ${theme.font.size.md};
      line-height: ${theme.spacing.stack.sm};
    }
    @media (min-width: 1171px) {
      text-align: start;
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.xxs};
    }
  `}
`

export const Email = styled(Paragraph)`
  ${({ theme }) => css`
    color: #8601c9;
    margin-top: ${theme.spacing.stack.small};
    @media (min-width: 768px) and (max-width: 1170px) {
      font-size: ${theme.font.size.md};
      line-height: ${theme.spacing.stack.sm};
      margin-top: ${theme.spacing.stack.small};
      font-weight: ${theme.font.weight.semiBold};
    }
    @media (min-width: 1171px) {
      font-weight: ${theme.font.weight.semiBold};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.sm};
      margin-top: ${theme.spacing.stack.small};
    }
  `}
`

export const FormTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.size.xs};
    text-align: center;
    margin: ${theme.spacing.stack.xs} 0 ${theme.spacing.stack.small};
    @media (min-width: 768px) and (max-width: 1170px) {
      margin: ${theme.spacing.stack.sm} 0 ${theme.spacing.stack.xxs};
      font-size: ${theme.font.size.md};
      line-height: ${theme.spacing.stack.sm};
    }
    @media (min-width: 1171px) {
      margin: ${theme.spacing.stack.xxxs} 0 ${theme.spacing.stack.small};
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.sm};
    }
  `}
`

export const ResendBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: ${theme.spacing.stack.xxs};
    @media (min-width: 768px) and (max-width: 1170px) {
      flex-direction: row;
      align-items: center;
      height: auto;
      justify-content: space-between;
      margin-top: ${theme.spacing.stack.xxl};
    }
    @media (min-width: 1171px) {
      flex-direction: row;
      align-items: center;
      height: auto;
      justify-content: space-between;
      margin-top: ${theme.spacing.stack.xl};
    }
  `}
`

export const ResendToken = styled.p<{ resendToken: boolean }>`
  ${({ theme, resendToken }) => css`
    align-self: center;
    margin-top: ${theme.spacing.stack.sm};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    color: ${resendToken ? '#39383a' : '#8601c9'};
    font-weight: ${theme.font.weight.bold};

    cursor: pointer;
    @media (min-width: 768px) and (max-width: 1170px) {
      font-size: ${theme.font.size.md};
      line-height: ${theme.spacing.stack.sm};
      margin-top: 0;
    }
    @media (min-width: 1171px) {
      font-size: ${theme.font.size.sm};
      line-height: ${theme.spacing.stack.sm};
      margin-top: 0;
    }
  `}
`

export const ButtonBox = styled.div`
  ${({ theme }) => css`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  `}
`

export const CodeInputStyle = styled(ReactCodeInput)`
  ${({ theme }) => css`
    input {
      border-radius: 6px;
      border: 1px solid lightgrey;
      margin: 4px;
      text-align: center;
      width: 36px;
      height: 42px;
      font-size: ${theme.font.size.md};
      box-sizing: border-box;
      color: black;
      background-color: white;
      background: #dfdfdf;
      :focus {
        background: #ffff;
        border: 1px solid #8601c9;
      }
    }
  `}
`
