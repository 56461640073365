import { backendApi } from '../../config'

export type Request = {
  firstName: string
  lastName: string
  corporateName?: string
  documentType: 'CNPJ' | 'CPF'
  email: string
  document: string
  password: string
  phone: string
  address: {
    city: string
    state: string
    zipCode: string
    country: string
    district: string
    number: number
    street: string
  }
  customField?: object
}

export const registerCreatorService = async (data: Request) => {
  const formatedDocument = data.document.replace(/[^0-9]/g, '')
  const formatedPhone = data.phone.replace(/[^0-9]/g, '')
  const formatedZipCode = data.address.zipCode?.replace(/[^0-9]/g, '')
  data.document = formatedDocument
  data.phone = formatedPhone
  data.address.zipCode = formatedZipCode
  if (data.address.number && data.address.number !== 0) {
    data.address.number = Number(data.address.number)
  }

  return await backendApi.post('/api/creators', data)
}
