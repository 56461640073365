import styled, { css } from 'styled-components'

import { ReactComponent as ArrowLeft } from '../../assets/icons/chevron-circle-left.svg'

type props = {
  colorText: 'white' | 'black'
}

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
  `}
`

export const IconBack = styled(ArrowLeft)`
  ${({ theme }) => css`
    fill: ${theme.color.base.nth1};
    margin-right: ${theme.spacing.stack.small};
  `}
`

export const Title = styled.p<props>`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.semiBold};
    font-size: ${theme.font.size.sm};
  `}

  ${(props) =>
    props.colorText === 'black'
      ? css`
          color: ${({ theme }) => theme.color.base.nth1};
        `
      : css`
          color: ${({ theme }) => theme.color.neutral.nth4};
        `}
`
