import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: ${theme.spacing.stack.xxs};
    @media (min-width: 768px) and (max-width: 1170px) {
      padding: ${theme.spacing.stack.xl} ${theme.spacing.stack.xxxl};
      align-items: center;
    }

    @media (min-width: 1171px) {
      padding: ${theme.spacing.stack.sm} ${theme.spacing.stack.xxxl};
      align-items: center;
    }
  `}
`

export const Logo = styled.img`
  ${({ theme }) => css`
    max-width: 200px;
    max-height: 75px;
    margin: 0 auto ${theme.spacing.stack.xxs};
    @media (min-width: 768px) {
      margin: 0 auto ${theme.spacing.stack.md};
    }
  `}
`

export const BackButtonBox = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs} ${theme.spacing.stack.xxs} 0;
  `}
`

export const Container = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (min-width: 768px) and (max-width: 1170px) {
      min-width: 522px;
      max-width: 522px;
    }
    @media (min-width: 1171px) {
      min-width: 418px;
      max-width: 418px;
    }
  `}
`

export const TextPassword = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.size.md};
    font-weight: 900;
    color: #39383a;
    margin: ${theme.spacing.stack.xxs} 0 ${theme.spacing.stack.xxs} 0;
    max-width: 212px;
    @media (min-width: 768px) and (max-width: 1170px) {
      font-size: 60px;
      max-width: 431px;
      font-weight: 900;
      color: #39383a;
      margin: ${theme.spacing.stack.xl} 0 ${theme.spacing.stack.xs} 0;
    }
    @media (min-width: 1171px) {
      font-size: ${theme.font.size.md};
      font-weight: ${theme.font.weight.semiBold};
      max-width: 100%;
      color: #39383a;
      margin: ${theme.spacing.stack.xl} 0 ${theme.spacing.stack.xxs} 0;
    }
  `}
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    height: 100%;
  }
`

export const PasswordRule = styled.p`
  ${({ theme }) => css`
    color: #6c01a3;
    font-size: ${theme.font.size.xs};
    line-height: ${theme.spacing.stack.xxs};
    font-weight: ${theme.font.weight.semiBold};
    @media (min-width: 768px) {
      font-size: ${theme.font.size.sm};
    }
  `}
`

export const ButtonBox = styled.div`
  ${({ theme }) => css`
    margin-top: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.stack.xxs};
    @media (min-width: 768px) {
      margin-top: ${theme.spacing.stack.xxl};
    }
    @media (min-width: 1171px) {
      margin-top: ${theme.spacing.stack.xl};
    }
  `}
`
