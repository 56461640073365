import { AnimatePresence } from 'framer-motion'
import { Switch, Route } from 'react-router-dom'
import { Login } from '../pages/login/Login'
import { LoginCreatorPwa } from '../pages/loginCreatorPwa/LoginCreatorPwa'
import ChangePasword from '../pages/ResetPassword/ChangePassword/ChangePassword'
import ForgotPassword from '../pages/ResetPassword/ForgotPassword/ForgotPassword'
import TokenVerification from '../pages/ResetPassword/TokenVerification/TokenVerification'
import { CustomerSignup } from '../pages/customerSignup/CustomerSignup'
import { Checkout } from '../pages/checkout/Checkout'
import { Register } from '../pages/register/Register'
import { CreatorSignup } from '../pages/creatorSignup/CreatorSignup'
import { Terms } from '../pages/Terms/Terms'

const Routes = () => {
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/loginCreator" component={LoginCreatorPwa} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/tokenverification" component={TokenVerification} />
        <Route path="/changepassword" component={ChangePasword} />
        <Route path="/customersignup" component={CustomerSignup} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/register" component={Register} />
        <Route path="/creatorsignup" component={CreatorSignup} />
        <Route exact path="/terms" component={Terms} />
        <Route path="/terms/:page" component={Terms} />
        <Route path="/url" component={Login} />
      </Switch>
    </AnimatePresence>
  )
}

export { Routes }
