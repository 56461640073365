import { AxiosResponse } from 'axios'
import { backendApi } from '../../config'

export type Request = {
  firstName: string
  lastName: string
  email: string
  document: string
  password: string
  phone?: string
  address?: {
    city: string
    state: string
    country: string
  }
  customField?: object
}

export type Response = {}

export const createCustomerService = async (request: Request) => {
  const formatedDocument = request.document.replace(/[^0-9]/g, '')
  const formatedPhone = request.phone?.replace(/[^0-9]/g, '')
  request.document = formatedDocument
  request.phone = formatedPhone

  const { data }: AxiosResponse<Response> = await backendApi.post(
    '/api/customers',
    request
  )

  return data
}
