import { Router } from 'react-router-dom'
import { Routes } from './routes/routes'
import { history } from './routes/services/history'
import { ThemeProvider } from 'styled-components'
import { AlertModal, theme } from 'applaus-ui-kit'
import GlobalStyles from './styles/GlobalStyles'
import { Loading } from './components/loading/Loading'
import { useContext } from 'react'
import { AuthContext } from './contexts/AuthContext'

function App() {
  const { loading, modalState, setModalState } = useContext(AuthContext)
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Routes />
        <GlobalStyles />
        <AlertModal
          open={modalState.open}
          variant={modalState.variant}
          message={modalState.message}
          description={modalState.description}
          buttonText={modalState.buttonText ? modalState.buttonText : 'Ok'}
          click={
            modalState.click
              ? modalState.click
              : () =>
                  setModalState({ message: '', open: false, variant: 'error' })
          }
        />
        <Loading isLoading={loading} />
      </ThemeProvider>
    </Router>
  )
}

export default App
