import * as S from './Terms.styles'
import { ButtonBack } from '../../../components/buttonBack/ButtonBack'
import { Paragraph } from 'applaus-ui-kit'
import { ReactComponent as ArrowRight } from '../../../assets/icons/chevron-right.svg'
import { history } from '../../../routes/services/history'

export const TermsMobile = () => {
  return (
    <S.Container>
      <ButtonBack title="Termos" click={() => history.push('/')} />

      <S.Options>
        <S.TermsOfUse onClick={() => history.push(`/terms/termsofuse`)}>
          <Paragraph variant="large" type="semiBold">
            Termos de uso
          </Paragraph>
          <ArrowRight />
        </S.TermsOfUse>

        <S.Divider />

        <S.CancellationPolicy
          onClick={() => history.push(`/terms/cancellationpolicy`)}>
          <Paragraph variant="large" type="semiBold">
            Política de cancelamento
          </Paragraph>
          <ArrowRight />
        </S.CancellationPolicy>

        <S.Divider />

        <S.PrivacyPolicy onClick={() => history.push(`/terms/privacypolicy`)}>
          <Paragraph variant="large" type="semiBold">
            Política de privacidade
          </Paragraph>
          <ArrowRight />
        </S.PrivacyPolicy>

        <S.Divider />

        <S.CookieDeclaration
          onClick={() => history.push(`/terms/cookiedeclaration`)}>
          <Paragraph variant="large" type="semiBold">
            Declaração de cookies
          </Paragraph>
          <ArrowRight />
        </S.CookieDeclaration>
      </S.Options>
    </S.Container>
  )
}
