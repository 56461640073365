import { api } from '../../config'

type Request = {
  email: string
}

export const sendTokenService = async (request: Request) => {
  const body = {
    email: request.email,
    clientId: process.env.REACT_APP_CLIENT_ID
  }
  await api.post('/api/v1/users/resetPassword/token', body)
}
