import * as S from './ButtonBack.styles'

type ButtonBackProps = {
  click?: () => void
  title: string
  colorText?: 'white' | 'black'
}

export const ButtonBack = ({
  click,
  title,
  colorText = 'black'
}: ButtonBackProps) => {
  return (
    <S.Container onClick={click}>
      <S.IconBack />
      <S.Title colorText={colorText}>{title}</S.Title>
    </S.Container>
  )
}
