import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    @media (max-width: 767px) {
      padding: 24px;
    }
    @media (min-width: 768px) {
      font-size: ${theme.font.size.xl};
      line-height: ${theme.spacing.stack.xl};
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 1.5rem;

    p {
      margin-bottom: ${theme.spacing.stack.xxs};
      text-align: justify;
    }
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.spacing.stack.xxs};
    color: #5c5c5c;
    white-space: nowrap;
    margin-bottom: ${theme.spacing.stack.small};

    @media (min-width: 768px) {
      font-size: ${theme.font.size.xl};
      line-height: ${theme.spacing.stack.xl};
    }
  `}
`
