import * as S from './ForgotPassword.styles'

import UnauthenticatedTemplate from '../../../components/unauthenticatedTemplate/Unauthenticated'
import templateImage from '../../../assets/templateImage.png'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Button, Hidden, Input } from 'applaus-ui-kit'
import { useHistory } from 'react-router'
import { sendTokenService } from '../../../api/services/user/sendTokenService'
import { useContext } from 'react'
import { ButtonBack } from '../../../components/buttonBack/ButtonBack'
import Logo from '../../../assets/logo.svg'
import { AuthContext } from '../../../contexts/AuthContext'
import { useWindowSize } from '../../../hooks/useWindowSize'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Insira um email válido')
    .required('Email é obrigatório')
})

const ForgotPassword = () => {
  const { setLoading, setModalState } = useContext(AuthContext)
  const { isMobile } = useWindowSize()

  const resetAlertModal = () => {
    setModalState({
      buttonText: '',
      message: '',
      click: () => {},
      open: false,
      variant: 'error'
    })
  }
  const history = useHistory()
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        await sendTokenService({ email: values.email })
        setLoading(false)
        history.push({ pathname: '/tokenverification', state: { values } })
      } catch (err: any) {
        setLoading(false)
        if (err.response) {
          setModalState({
            buttonText: 'Fechar',
            click: () => resetAlertModal(),
            message:
              err.response.status === 429
                ? 'Você alcançou o limite de pedidos diários'
                : 'Ocorreu Algum erro, tente novamente mais tarde',
            open: true,
            variant: 'error'
          })
        } else {
          setModalState({
            buttonText: 'Fechar',
            click: () => resetAlertModal(),
            message: 'Ocorreu Algum erro, tente novamente mais tarde',
            open: true,
            variant: 'error'
          })
        }
      }
    }
  })

  return (
    <UnauthenticatedTemplate image={templateImage}>
      <S.Wrapper>
        <Hidden mobile>
          <S.Logo src={Logo} />
        </Hidden>
        <S.Container
          initial={{ opacity: 0, x: '-20%' }}
          transition={{ duration: 1 }}
          animate={{ opacity: 1, x: 0 }}>
          <ButtonBack
            title={isMobile ? '' : 'Voltar'}
            click={() => {
              if (
                document.referrer !== process.env.REACT_APP_CUSTOMER_URL ||
                document.referrer !== process.env.REACT_APP_CREATOR_URL ||
                document.referrer !== process.env.REACT_APP_BACKOFFICE_URL
              ) {
                history.push('/')
              } else history.goBack()
            }}
          />

          <S.TextPassword>Esqueceu sua senha?</S.TextPassword>

          <S.Form onSubmit={formik.handleSubmit}>
            <Input
              name="email"
              label="Digite seu email"
              placeholder="Email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              texterror={formik.errors.email}
              onChange={formik.handleChange}
              value={formik.values.email}
              fullWidth
            />

            <Hidden mobile>
              <S.ButtonBox>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth={false}>
                  Continuar
                </Button>
              </S.ButtonBox>
            </Hidden>

            <Hidden tablet desktop style={{ marginTop: 'auto' }}>
              <S.ButtonBox>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth={false}>
                  Continuar
                </Button>
              </S.ButtonBox>
            </Hidden>
          </S.Form>
        </S.Container>
      </S.Wrapper>
    </UnauthenticatedTemplate>
  )
}

export default ForgotPassword
