import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.stack.xxs};
  `}
`

export const Divider = styled.div`
  ${({ theme }) =>
    css`
      border: 1px solid #dfdfdf;
      margin: ${theme.spacing.stack.small} 0;
    `}
`

export const Options = styled.div`
  ${({ theme }) => css`
    margin-top ${theme.spacing.stack.xxs}
  `}
`

export const TermsOfUse = styled.div`
  ${({ theme }) =>
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    `}
`

export const CancellationPolicy = styled.div`
  ${({ theme }) =>
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    `}
`

export const PrivacyPolicy = styled.div`
  ${({ theme }) =>
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    `}
`

export const CookieDeclaration = styled.div`
  ${({ theme }) =>
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${theme.spacing.stack.small} ${theme.spacing.stack.xxxs};
    `}
`
