import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { theme } from 'applaus-ui-kit'

type ParagrapghProps = {
  variant: 'large' | 'regular' | 'small'
  type?: 'bold' | 'normal' | 'semiBold'
}

type Theme = typeof theme

const ParagraphModifiers = {
  large: (theme: Theme) => css`
    ${media.lessThan('medium')`
        font-size: ${theme.font.size.sm};
        line-height: ${theme.spacing.stack.xxs};
    `}
    ${media.between('medium', 'large')`
        font-size: ${theme.font.size.sm};
        line-height: ${theme.spacing.stack.xxs};
    `}
    ${media.greaterThan('medium')`
        font-size:${theme.font.size.sm};
        line-height: ${theme.spacing.stack.xxs};
    `}
  `,
  regular: (theme: Theme) => css`
    ${media.lessThan('medium')`
        font-size: ${theme.font.size.sm};
        line-height: ${theme.spacing.stack.xxs};
    `}
    ${media.between('medium', 'large')`
        font-size: ${theme.font.size.sm};
        line-height: ${theme.spacing.stack.xxs};
    `}
        ${media.greaterThan('medium')`
        font-size:${theme.font.size.sm};
        line-height: ${theme.spacing.stack.xxs};
    `}
  `,
  small: (theme: Theme) => css`
    ${media.lessThan('medium')`
        font-size: ${theme.font.size.xs};
        line-height: 21px;
    `}
    ${media.between('medium', 'large')` 
        font-size: ${theme.font.size.xs};
        line-height: 21px;
    `}
    ${media.greaterThan('medium')`
        font-size: ${theme.font.size.xs};
        line-height: 21px;
    `}
  `,
  bold: (theme: Theme) => css`
    font-weight: ${theme.font.weight.bold};
  `,
  semiBold: (theme: Theme) => css`
    font-weight: ${theme.font.weight.semiBold};
  `,
  normal: (theme: Theme) => css`
    font-weight: ${theme.font.weight.regular};
  `
}

export const Paragraph = styled('p')<ParagrapghProps>`
  ${({ theme, variant, type }) => css`
    ${ParagraphModifiers[variant](theme)}
    ${type && ParagraphModifiers[type](theme)} 
    color: ${theme.color.base.nth1};
  `}
`
