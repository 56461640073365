import { useLocation } from 'react-router'
import { useWindowSize } from '../../hooks/useWindowSize'
import { CancellationPolicy } from './components/CancellationPolicy/CancellationPolicy'
import { CookieDeclaration } from './components/CookieDeclaration/CookieDeclaration'
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy'
import { TermsOfUse } from './components/TermsOfUse/TermsOfUse'
import { TermsMobile } from './Mobile/TermsMobile'
import { TermsDesktop } from './Desktop/TermsDesktop'

export const Terms = () => {
  const { isMobile } = useWindowSize()
  const location = useLocation()

  if (isMobile) {
    switch (location.pathname) {
      case '/terms/termsofuse':
        return <TermsOfUse />
      case '/terms/privacypolicy':
        return <PrivacyPolicy />
      case '/terms/cancellationpolicy':
        return <CancellationPolicy />
      case '/terms/cookiedeclaration':
        return <CookieDeclaration />
      default:
        return <TermsMobile />
    }
  } else {
    return <TermsDesktop />
  }
}
