export const messageHelper = {
  yup: {
    creator: {
      success: {},
      error: {
        corporateNameIsMandatory: 'Razão social é obrigatória',
        nameIsMandatory: 'Nome é obrigatório.',
        shortName: 'Nome deve ter pelos 3 caracters.',
        shortLastName: 'Sobrenome deve ter pelo menos 3 caracteres',
        lastNameIsMandatory: 'Sobrenome é obrigatório.',
        cnpjIsMandatory: 'CNPJ é obrigatório.',
        cnpjIsInvalid: 'CNPJ inválido.',
        documentIsMandatory: 'CPF é obrigatório.',
        documentIsInvalid: 'CPF é inválido.',
        emailIsMandatory: 'Email é obrigatório.',
        emailIsInvalid: 'Digite um email válido.',
        phoneIsMandatory: 'Telefone é obrigatório.',
        phoneIsInvalid: 'Telefone inválido.',
        cepIsMandatory: 'CEP é obrigatório.',
        cepIsInvalid: 'CEP inválido.',
        streetIsMandatory: 'Rua é obrigatória.',
        districtIsMandatory: 'Bairro é obrigatório.',
        cityIsMandatory: 'Cidade é obrigatória.',
        stateIsMandatory: 'Estado é obrigatório.',
        confirmPasswordText: 'Confirmar senha é obrigatória',
        ruleMatchPasswordIncorrectText: 'Senhas não coincidem',
        rulePassowrdIncorrectText: 'Regras de senha não atendidas'
      }
    }
  }
}
