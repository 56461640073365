import { createContext, ReactNode, useState } from 'react'
import { ModalState } from './AuthTypes'

type AuthProviderProps = {
  children: ReactNode
}

export type AuthContextProps = {
  loading: boolean
  setLoading: (loading: boolean) => void
  modalState: ModalState
  setModalState: (modalState: ModalState) => void
}

const initialValue: AuthContextProps = {
  loading: false,
  setLoading: () => {},
  modalState: {
    message: '',
    open: false,
    variant: 'error'
  },
  setModalState: () => {}
}

export const AuthContext = createContext<AuthContextProps>(initialValue)

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [loading, setLoading] = useState(initialValue.loading)
  const [modalState, setModalState] = useState<ModalState>({
    message: initialValue.modalState.message,
    open: initialValue.modalState.open,
    variant: initialValue.modalState.variant
  })

  return (
    <AuthContext.Provider
      value={{
        loading,
        setLoading,
        modalState,
        setModalState
      }}>
      {children}
    </AuthContext.Provider>
  )
}
