import * as S from './SocialLogin.styles'
import {
  IResolveParams,
  LoginSocialFacebook,
  LoginSocialGoogle
} from 'reactjs-social-login'
export const SocialLogin = () => {
  return (
    <S.Container>
      <S.TitleDiv>
        <S.SemiDivider />
        <S.Text>ou</S.Text>
        <S.SemiDivider />
      </S.TitleDiv>
      <S.SocialLoginContainer>
        <LoginSocialGoogle
          client_id={process.env.REACT_APP_GG_APP_ID || ''}
          scope="openid profile email"
          discoveryDocs="claims_supported"
          access_type="offline"
          onResolve={({ provider, data }: IResolveParams) => {
            console.log(provider, data)
          }}
          onReject={(err) => {
            console.log(err)
          }}>
          <S.SocialLoginButton>
            <S.GoogleIcon />
            <p>Continuar com o Google</p>
          </S.SocialLoginButton>
        </LoginSocialGoogle>
      </S.SocialLoginContainer>
      <S.SocialLoginContainer>
        <LoginSocialFacebook
          appId={process.env.REACT_APP_FB_APP_ID || ''}
          fieldsProfile={
            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
          }
          redirect_uri={'google.com.br'}
          onResolve={({ provider, data }: IResolveParams) => {
            console.log(provider, data)
          }}
          onReject={(err) => {
            console.log(err)
          }}>
          <S.SocialLoginButton>
            <S.FacebookIcon />
            <p>Continuar com o Facebook</p>
          </S.SocialLoginButton>
        </LoginSocialFacebook>
      </S.SocialLoginContainer>
    </S.Container>
  )
}
