import styled, { css } from 'styled-components'

import { ReactComponent as GoogleFile } from '../../../../assets/icons/google.svg'
import { ReactComponent as FacebookFile } from '../../../../assets/icons/facebook.svg'

export const GoogleIcon = styled(GoogleFile)``
export const FacebookIcon = styled(FacebookFile)``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  width: 100%;
  margin: 16px 0;
`

export const SemiDivider = styled.div`
  ${() => css`
    border-bottom: 1px solid #cfcfcf;
    width: 50%;
  `}
`

export const Text = styled.p`
  ${() => css`
    color: #cfcfcf;
    font-size: 2rem;
    font-weight: 600;
    margin: 0 2rem;
  `}
`

export const SocialLoginContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    cursor: pointer;
  `}
`

export const SocialLoginButton = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.stack.small};
    width: 100%;
    background: ${theme.color.base.nth4};
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: ${theme.spacing.stack.small} 0 ${theme.spacing.stack.small}
      ${theme.spacing.stack.xxxs};
    > p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      margin: 0 auto;
    }
  `}
`
